import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MiddlwareService {
  id: any;
  email: any;
  token: any;
  order: any;
  profile: any;
  returnUrl = "/";
  recharge_amount: any;
  showAdmin = false;
  errorMap = {
    12000: {
      title: "Failed",
      html: "Profile Not Saved",
      icon: "error"
    },
    12003: {
      title: "Failed",
      html: "Domain Not Saved",
      icon: "error"
    },
    12009: {
      title: "Failed",
      html: "Order Not Created",
      icon: "error"
    },
    12010: {
      title: "Failed",
      html: "Order Not Approved",
      icon: "error"
    },
    12011: {
      title: "Failed",
      html: "Order Not Approved",
      icon: "error"
    },
    12026: {
      title: "Failed",
      html: "Domain Already Exists",
      icon: "error"
    },
    12027: {
      title: "Failed",
      html: "Domain Edit Not Saved",
      icon: "error"
    }
  }
  balance: any;

  constructor(
  ){}

  public _editDomainContainer = new BehaviorSubject<any>(undefined);
  public editDomainContainer$ = this._editDomainContainer.asObservable();

  public _rechargeContainer = new BehaviorSubject<any>(undefined);
  public rechargeContainer$ = this._rechargeContainer.asObservable();

  public _idContainer = new BehaviorSubject<any>(undefined);
  public idContainer$ = this._idContainer.asObservable();

  public _emailContainer = new BehaviorSubject<any>(undefined);
  public emailContainer$ = this._emailContainer.asObservable();

  public _tokenContainer = new BehaviorSubject<any>(undefined);
  public tokenContainer$ = this._tokenContainer.asObservable();

  public _orderContainer = new BehaviorSubject<any>(undefined);
  public orderContainer$ = this._orderContainer.asObservable();

  public _profileContainer = new BehaviorSubject<any>(undefined);
  public profileContainer$ = this._profileContainer.asObservable();

  public _showAdminContainer = new BehaviorSubject<any>(undefined);
  public showAdminContainer$ = this._showAdminContainer.asObservable();
  public _showRootAdminContainer = new BehaviorSubject<any>(undefined);
  public showRootAdminContainer$ = this._showRootAdminContainer.asObservable();

  idSubscription = this.idContainer$.subscribe((received_id) => {
    this.id = received_id;
  });

  emailSubscription = this.emailContainer$.subscribe((received_email) => {
    this.email = received_email;
  });

  tokenSubscription = this.tokenContainer$.subscribe((received_token) => {
    this.token = received_token;
  });

  orderSubscription = this.orderContainer$.subscribe((received_order) => {
    this.order = received_order;
  });

  profileSubscription = this.profileContainer$.subscribe((received_profile) => {
    this.profile = received_profile;
  });

  rechargeSubscription = this.rechargeContainer$.subscribe((received_amount) => {
    this.recharge_amount = received_amount;
  });

  getID(){
    return this.id;
  }

  getEmail(){
    return this.email;
  }

  getToken(){
    return this.token;
  }

  getOrder(){
    return this.order;
  }

  getProfile(){
    return this.profile;
  }

  getRecharge(){
    return this.recharge_amount;
  }

  mapError(code: number){
    return this.errorMap[code];
  }

  clear(){
    this.id = null;
    this.email = null;
    this.token = null;
    this.order = null;
    this.profile = null;
    this.returnUrl = "/";
  }

  makeRef(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }
}
