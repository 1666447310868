import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { ImageCropperComponent } from 'ngx-image-cropper';

@Component({
  selector: 'app-imagecropper',
  templateUrl: './imagecropper.component.html',
  styleUrls: ['./imagecropper.component.scss']
})

/**
 * UI-Image-cropper component
 */
export class ImagecropperComponent implements OnInit {

  constructor() { }

  // bread crumb items
  breadCrumbItems: Array<{}>;

  transform: ImageTransform = {};

  imageChangedEvent: any = '';
  croppedImage: any = '';
  scale = 1;
  @Input() image: string;
  @Input() normalized_ratio: number;
  @Input() width: number;
  @Output() newImage = new EventEmitter<string>();
  @ViewChild(ImageCropperComponent, { static: true }) imageCropper: ImageCropperComponent;

  ngOnInit(): void {
  }

  /**
   * Crop image
   * @param event image passed
   */
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  setImage(enc){
    this.image = enc;
  }

  saveImage(){
    this.newImage.emit(this.croppedImage);
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
}
