// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ws_url: 'wss://s1.linkexchange.ai:4040/',
  http_url:'https://s1.linkexchange.ai:4040',
  client_id: "446452655386-qbhsrii2dh23jcr54k5qoc36f588f6vj.apps.googleusercontent.com",
  paypal_client_id: "AfpMA2EWdVxcpUWwGBjl1M0ijCyF1MKChTgM6vIzlpyVcLaLx1fhM_juQi-uj0diLUFofQS_Wg9ac9gc"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
