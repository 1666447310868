<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box ps-3">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm ps-2">
            <img src="assets/images/logo.svg" alt="" height="28">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" alt="" height="37">
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm ps-2">
            <img src="assets/images/logo-light.svg" alt="" height="28">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-light.png" alt="" height="37">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item" (click)="toggleMenubar()">
        <i class="fa fa-fw fa-bars"></i>
      </button>
    </div>

    <div class="d-flex align-items-center">
      <h2 *ngIf="admin && !(super)">Administrator</h2>
      <h2 *ngIf="admin && super">Super Administrator</h2>
    </div>

    <div class="d-flex">
      <div *ngIf="balance != null" class="dropdown d-flex align-items-center ms-1">
        <button type="button" class="btn border border-secondary" (click)="routeRecharge()">
          <h6 class="mb-0 fw-600">USD ${{balance}}</h6>
        </button>
      </div>

      <div class="dropdown d-none d-lg-inline-block me-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>

      <!-- <div class="dropdown d-inline-block" dropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"
          dropdownToggle>
          <i class="bx bx-bell bx-tada"></i>
          <span class="badge bg-danger rounded-pill">3</span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" *dropdownMenu
          aria-labelledby="page-header-notifications-dropdown">
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">{{ 'HEADER.NOTIFICATIONS.TITLE' | translate}} </h6>
              </div>
              <div class="col-auto">
                <a href="javascript: void(0);" class="small">{{ 'HEADER.NOTIFICATIONS.VIEW_ALL' | translate}}</a>
              </div>
            </div>
          </div>
          <ngx-simplebar style="position: relative; height: 230px;" >
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs me-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TEXT' | translate}}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>
                      {{ 'HEADER.NOTIFICATIONS.FIRST.TIME' | translate}}</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <img src="assets/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic">
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TEXT' | translate}}</p>
                    <p class="mb-0"><i
                        class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.SECOND.TIME' | translate}}</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs me-3">
                  <span class="avatar-title bg-success rounded-circle font-size-16">
                    <i class="bx bx-badge-check"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.THIRD.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.THIRD.TEXT' | translate}}</p>
                    <p class="mb-0"><i
                        class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.THIRD.TIME' | translate}}</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <img src="assets/images/users/avatar-4.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic">
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TEXT' | translate}}</p>
                    <p class="mb-0"><i
                        class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.FOUR.TIME' | translate}}</p>
                  </div>
                </div>
              </div>
            </a>
          </ngx-simplebar>
          <div class="p-2 border-top d-grid">
            <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">
              <i class="mdi mdi-arrow-right-circle me-1"></i> {{ 'HEADER.NOTIFICATIONS.LOAD_MORE' | translate}}
            </a>
          </div>
        </div>
      </div> -->

      <div class="dropdown d-inline-block" dropdown>
        <button type="button" class="btn header-item" dropdownToggle id="page-header-user-dropdown">
          <img class="rounded-circle header-profile-user" src={{profile_image}} onerror="this.onerror=null; this.src='assets/images/users/account.webp'">
          <span class="d-none d-xl-inline-block ms-1">{{profile_name}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" *dropdownMenu>
          <a class="dropdown-item" routerLink="/account/profile"><i class="bx bx-user font-size-16 align-middle me-1"></i>
            {{ 'HEADER.LOGIN.PROFILE' | translate}}</a>
          <a class="dropdown-item enablePointer" (click)="routeRecharge()"><i class="bx bx-wallet font-size-16 align-middle me-1"></i>
            {{ 'HEADER.LOGIN.MY_WALLET' | translate}}</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()">
            <i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>
        </div>
      </div>

      <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item noti-icon right-bar-toggle" (click)="toggleMode()">
          <i class="bx bxs-moon"></i>
        </button>
      </div>

      <!-- <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item noti-icon right-bar-toggle" (click)="onSettingsButtonClicked()">
          <i class="bx bx-cog bx-spin"></i>
        </button>
      </div> -->
    </div>
  </div>
</header>
<div class="topnav">
  <div class="container-fluid">
    <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
      <div class="collapse navbar-collapse active" id="topnav-menu-content">
        <ul class="navbar-nav">
          <ng-container *ngFor="let item of menuItems">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle arrow-none text-color-menu" *ngIf="!item.subItems" href="javascript: void(0);"
                id="topnav-components" [routerLink]="item.link" role="button">
                <i class="bx {{item.icon}} me-2"></i>{{ item.label | translate }} <div class="arrow-down"
                  *ngIf="hasItems(item)"></div>
              </a>

              <a *ngIf="item.subItems" class="nav-link dropdown-toggle arrow-none text-color-menu"
                href="javascript: void(0);" id="topnav-components" role="button" (click)="onMenuClick($event)">
                <i class="bx {{item.icon}} me-1"></i>
                {{item.label| translate }}
                <div class="arrow-down"></div>
              </a>

              <div class="dropdown-menu" [ngClass]="{'dropdown-mega-menu-xl px-2':  item.subItems.length > 11}" aria-labelledby="topnav-dashboard" *ngIf="hasItems(item)">
                <ng-template ngFor let-i="index" let-subitem [ngForOf]="item.subItems">
                  <a *ngIf="item.subItems.length < 11 && !hasItems(subitem)" class="col dropdown-item side-nav-link-ref"
                  [routerLink]="subitem.link"
                  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                   {{subitem.label | translate }}
              </a>
              <div *ngIf="item.subItems.length > 11">
                  <div *ngIf="i % 3 == 0" class="row">
                      <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active" [routerLink]="subitem.link">{{item.subItems[i].label | translate}}</a></div>
                      <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active" [routerLink]="item.subItems[i + 1]?.link">{{item.subItems[i + 1]?.label | translate}}</a></div>
                      <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active" [routerLink]="item.subItems[i + 2]?.link">{{item.subItems[i + 2]?.label | translate}}</a></div>
                  </div>
              </div>

                <div class="dropdown" *ngIf="hasItems(subitem)">
                  <a class="dropdown-item dropdown-toggle" href="javascript: void(0);"
                    (click)="onMenuClick($event)">{{ subitem.label | translate }}
                    <div class="arrow-down"></div>
                  </a>
                  <div class="dropdown-menu">
                    <ng-template ngFor let-subSubitem [ngForOf]="subitem.subItems">
                      <a class="dropdown-item side-nav-link-ref" *ngIf="!hasItems(subSubitem)"
                        href="javascript: void(0);" [routerLink]="subSubitem.link"
                        routerLinkActive="active">{{ subSubitem.label | translate }}</a>
                      <div class="dropdown" *ngIf="hasItems(subSubitem)">

                        <a class="dropdown-item dropdown-toggle" href="javascript: void(0);"
                          (click)="onMenuClick($event)">{{ subSubitem.label | translate }}
                          <div class="arrow-down"></div>
                        </a>
                        <div class="dropdown-menu">
                          <ng-template ngFor let-subSubSubitem [ngForOf]="subSubitem.subItems">
                            <a class="dropdown-item side-nav-link-ref" href="javascript: void(0);"
                              [routerLink]="subSubSubitem.link"
                              routerLinkActive="active">{{ subSubSubitem.label | translate }}</a>
                          </ng-template>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </ng-template>
             </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </nav>
  </div>
</div>
