import { MiddlwareService } from 'src/app/core/services/middleware.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  ws: any;
  url = environment.ws_url;
  pingInterval: any;
  verified = false;
  component_ref = this.mdw.makeRef(16);
  public _getProfileContainer = new Subject<any>;
  public getProfileContainer$ = this._getProfileContainer.asObservable();
  public _myDomainsContainer = new Subject<any>;
  public myDomainsContainer$ = this._myDomainsContainer.asObservable();
  public _listMyExchangeDomainsContainer = new Subject<any>;
  public listMyExchangeDomainsContainer$ = this._listMyExchangeDomainsContainer.asObservable();
  public _searchDomainsContainer = new Subject<any>;
  public searchDomainsContainer$ = this._searchDomainsContainer.asObservable();
  public _getDomainContainer = new Subject<any>;
  public getDomainContainer$ = this._getDomainContainer.asObservable();
  public _getMyDomainContainer = new Subject<any>;
  public getMyDomainContainer$ = this._getMyDomainContainer.asObservable();
  public _getOrderContainer = new Subject<any>;
  public getOrderContainer$ = this._getOrderContainer.asObservable();
  public _getOtherServiceContainer = new Subject<any>;
  public getOtherServiceContainer$ = this._getOtherServiceContainer.asObservable();
  public _getChatContainer = new Subject<any>;
  public getChatContainer$ = this._getChatContainer.asObservable();
  public _getMessageContainer = new Subject<any>;
  public getMessageContainer$ = this._getMessageContainer.asObservable();
  public _otherAccountOnlineContainer = new Subject<any>;
  public otherAccountOnlineContainer$ = this._otherAccountOnlineContainer.asObservable();
  public _senderVerifyServiceContainer = new Subject<any>;
  public senderVerifyServiceContainer$ = this._senderVerifyServiceContainer.asObservable();
  public _receiverVerifyServiceContainer = new Subject<any>;
  public receiverVerifyServiceContainer$ = this._receiverVerifyServiceContainer.asObservable();
  public _testContainer = new Subject<any>;
  public testContainer$ = this._testContainer.asObservable();
  public _myOrdersContainer = new Subject<any>;
  public myOrdersContainer$ = this._myOrdersContainer.asObservable();
  public _highlightContainer = new Subject<any>;
  public highlightContainer$ = this._highlightContainer.asObservable();
  public _rechargeContainer = new Subject<any>;
  public rechargeContainer$ = this._rechargeContainer.asObservable();
  public _createPaymentContainer = new Subject<any>;
  public createPaymentContainer$ = this._createPaymentContainer.asObservable();
  public _paymentFeesContainer = new Subject<any>;
  public paymentFeesContainer$ = this._paymentFeesContainer.asObservable();
  public _payoutContainer = new Subject<any>;
  public payoutContainer$ = this._payoutContainer.asObservable();
  public _billingHistoryContainer = new Subject<any>;
  public billingHistoryContainer$ = this._billingHistoryContainer.asObservable();

  public _getMyProfileContainer = new BehaviorSubject<any>(undefined);
  public getMyProfileContainer$ = this._getMyProfileContainer.asObservable();
  public _verifyContainer = new BehaviorSubject<any>(false);
  public verifyContainer$ = this._verifyContainer.asObservable();
  public _getBalanceContainer = new BehaviorSubject<any>(undefined);
  public getBalanceContainer$ = this._getBalanceContainer.asObservable();

  constructor(
    private router: Router,
    private mdw: MiddlwareService,
  ) {}

  ngOnInit(): void {
    localStorage.setItem("auth", "false");
  }

  //opens websocket if not open and responds based on server reply. Responds include functions for listing wireframes in database and deleting wireframes.
  sendRequest(data: any) {
    if (!this.ws || this.ws.readyState != WebSocket.OPEN) {
      // console.log("url: " + this.url);
      this.ws = new WebSocket(this.url);
      // this.ws.onopen = (event: any) => console.log(event);
      this.ws.addEventListener('open', (evt: any) => {
        // console.log("opened");
        this.pinger();
        this.sendTheRequest(data);
      });
      this.ws.addEventListener('message', (evt: any) => {
        clearInterval(this.pingInterval);
        this.pinger();
        console.log(evt.data);
        let data = JSON.parse(evt.data);
        this._testContainer.next(data);
        switch(data.method){
          case("TOKEN_AUTH_OK"):
            localStorage.setItem("auth", "true");
            this.mdw._emailContainer.next(data.email);
            this.mdw._tokenContainer.next(data.token);
            this.mdw._idContainer.next(data.id);
            localStorage.setItem('email', data.email);
            localStorage.setItem('token', data.token);
            localStorage.setItem('id', data.id);
            localStorage.setItem("reconnect", "true");
            if(localStorage.getItem('returnUrl')){
              this.router.navigate([localStorage.getItem('returnUrl')]);
            } else {
              this.router.navigate(['/']);
            }
            let old_profile = this.mdw.getProfile();
            if(old_profile == null || old_profile.account_id !== data.id){
              this.mdw._profileContainer.next(null);
              this.processMethod(
                "getMyProfile",
                {},
                this.component_ref
              )
            }
            this.processMethod(
              "getBalance",
              {},
              this.component_ref
            )
            this.verified = true;
            this._verifyContainer.next(true);
          break;

          case("profileResponse"):
            this._getProfileContainer.next(data);
          break;
          case("myProfileResponse"):
            if(this.component_ref === data.ref){
              this.mdw._profileContainer.next(data.data);
            }
            this._getMyProfileContainer.next(data);
            if(data.data.role == 1 || data.data.role == 2){
              this.mdw._showAdminContainer.next(true);
            } else {
              // console.log("admin false");
              this.mdw._showAdminContainer.next(false);
            }
            if(data.data.role == 2){
              this.mdw._showRootAdminContainer.next(true);
            } else {
              this.mdw._showRootAdminContainer.next(false);
            }
          break;

          case("myDomainsResponse"):
            this._myDomainsContainer.next(data);
          break;
          case("listMyExchangeDomainsResponse"):
            this._listMyExchangeDomainsContainer.next(data);
          break;
          case("getMyDomainResponse"):
            this._getMyDomainContainer.next(data);
          break;
          case("getDomainResponse"):
            this._getDomainContainer.next(data);
          break;
          case("searchDomainsResponse"):
            this._searchDomainsContainer.next(data);
          break;

          case("getOrderResponse"):
            this._getOrderContainer.next(data);
          break;
          case("myOrdersResponse"):
            this._myOrdersContainer.next(data);
          break;
          case("getOtherServiceResponse"):
            this._getOtherServiceContainer.next(data);
          break;

          case("getChatResponse"):
            this._getChatContainer.next(data);
          break;
          case("message"):
            this._getMessageContainer.next(data);
          break;
          case("accountOnlineResponse"):
            this._otherAccountOnlineContainer.next(data);
          break;

          case("senderVerifyResponse"):
            this._senderVerifyServiceContainer.next(data);
          break;
          case("receiverVerifyResponse"):
            this._receiverVerifyServiceContainer.next(data);
          break;
          case("highlightResponse"):
            this._highlightContainer.next(data);
          break;

          case("rechargeResponse"):
            this._rechargeContainer.next(data);
          break;
          case("createPaymentResponse"):
            this._createPaymentContainer.next(data);
          break;
          case("getBalanceResponse"):
            this._getBalanceContainer.next(data);
          break;
          case("getPaymentFeesResponse"):
            this._paymentFeesContainer.next(data);
          break;
          case("payoutResponse"):
            this._payoutContainer.next(data);
          break;
          case("getBillingHistoryResponse"):
            this._billingHistoryContainer.next(data);
          break;

          case("error"):
            this.errorMessage(data);
          break;

          case("PONG"):
            // console.log("PONG");
          break;
        }
      });
      this.ws.addEventListener('close', async (event: any) => {
        this.verified = false;
        this._verifyContainer.next(false);
        // console.log("closed");
        clearInterval(this.pingInterval);
        // console.log(event);
        switch(event.code){
          case(4000):
          case(4003):
            this.reconnect(3);
          break;

          case(1006):
            await this.sleep(4000);
            this.reconnect(3);
          break;

          case(4001):
          case(4002):
          case(4004):
          case(1005):
            localStorage.removeItem("id");
            localStorage.removeItem("email");
            localStorage.removeItem("token");
            localStorage.removeItem("returnUrl");
            this.mdw.clear();
            localStorage.setItem("auth", "false");
            this.router.navigate(["/hub/login"])
            .then(() =>
              window.location.reload()
            );
          break;
        }
      });
      this.ws.addEventListener('error', (event: any) => {
        // console.log(event);
      });
    }
  }

  //sends request to websocket
  sendTheRequest(this: any, data: string) {
    // console.log("sending request " + data);
    if (!this.ws || this.ws.readyState != WebSocket.OPEN){
      throw "WebSocket not open";
    }
    console.log(data);
    this.ws.send(data);
    clearInterval(this.pingInterval);
    this.pinger();
  }

  pinger() {
    this.pingInterval = setInterval(() => {
      this.ws.send(JSON.stringify({method:"PING"}));
    }, 25000);
  }

  async reconnect(retry: number){
    let connected = false;
    let token = localStorage.getItem('token');
    let id = localStorage.getItem('id');
    let email = localStorage.getItem('email');

    if(token && email && id){
      localStorage.setItem("reconnect", null)

      for(let i = 0; i < retry; i++){
        // console.log("try reconnecting");
        await this.reconnectWebsocket(token, id, email);
        await this.sleep(5000);
        if(this.verified){
          connected = true;
          break;
        }
      }
      if(!(connected)){
        console.log("not verified");
        localStorage.removeItem("id");
        localStorage.removeItem("email");
        localStorage.removeItem("token");
        localStorage.removeItem("returnUrl");
        this.mdw.clear();
        localStorage.setItem("auth", "false");
        this.router.navigate(["/hub/login"])
        .then(() =>
          window.location.reload()
        );
      }
    } else {
      localStorage.removeItem("id");
      localStorage.removeItem("email");
      localStorage.removeItem("token");
      localStorage.removeItem("returnUrl");
      this.mdw.clear();
      localStorage.setItem("auth", "false");
    }
  }

  processMethod(method: any, data: any, ref: any){
    switch(method){
      case('setProfile'):
      case('getMyProfile'):
      case('getProfile'):

      case("setDomain"):
      case("updateDomain"):
      case("getDomain"):
      case("getMyDomain"):
      case("getDomainListing"):
      case("myDomains"):
      case("listMyExchangeDomains"):
      case("searchDomains"):

      case("requestOrder"):
      case("getOrder"):
      case("getOtherService"):
      case("myOrders"):
      case("updateOrderService"):
      case("approveOrder"):
      case("approveOrderExchange"):

      case("accountOnline"):
      case("message"):
      case("getChat"):

      case("senderVerify"):
      case("receiverVerify"):
      case("highlight"):

      case("recharge"):
      case("createPayment"):
      case("getBalance"):
      case("getPaymentFees"):
      case("payout"):
      case("getBillingHistory"):
      case("updatePayout"):

      case("adminUpdateOrder"):
      case("adminTransfer"):
        this.sendTheRequest(JSON.stringify({
          method: method,
          data: data,
          ref: ref
        }));
      break;
    }
  }

  errorMessage(data){
    switch(data.code){
      case(12000):
        this._getMyProfileContainer.next({error: data.code, ref: data.ref});
      break;
      case(12003):
      case(12026):
      case(12027):
        this._getMyDomainContainer.next({error: data.code, ref: data.ref});
      break;
      case(12009):
      case(12010):
      case(12011):
      case(12012):
        this._getOrderContainer.next({error: data.code, ref: data.ref});
      break;
      case(12016):
        this._senderVerifyServiceContainer.next({error: data.code, ref: data.ref});
      break;
      case(12017):
        this._receiverVerifyServiceContainer.next({error: data.code, ref: data.ref});
      break;
      case(12018):
        this._highlightContainer.next({error: data.code, ref: data.ref});
      break;
      case(12028):
        this._createPaymentContainer.next({error: data.code, ref: data.ref});
      break;
    }
  }

  sleep(ms: number){
    return new Promise(val => setTimeout(val, ms));
  }

  logout(){
    localStorage.removeItem("id");
    localStorage.removeItem("email");
    localStorage.removeItem("token");
    localStorage.removeItem("returnUrl");
    this.mdw.clear();
    localStorage.setItem("auth", "false");
    if(this.ws){
      this.ws.close();
    } else {
      this.router.navigate(["/hub/login"])
      .then(() =>
        window.location.reload()
      );
    }
  }

  async reconnectWebsocket(token: any, id: any, email: any){
    let request = fetch(environment.http_url + '/reconnect', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'email': email,
        'id': id,
        'token': token
      })
    });

    request.then((response: any) => {
      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`);
      }
      return response.json();
    }).then((data) => {
      if(data.token != null && data.email != null && data.id != null) {
        this.sendRequest(JSON.stringify({method: 'TOKEN_AUTH', token: data.token, email: data.email}));
      } else {
        localStorage.setItem("auth", "false");
      }
    }).catch((err: any) => {
    });
  }
}
