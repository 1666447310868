import { EventService } from './../../core/services/event.service';
import { Component, OnInit, Output, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { WebsocketService } from 'src/app/core/services/websocket.service';
import { MiddlwareService } from 'src/app/core/services/middleware.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {
  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  profileSubscription: any;
  getBalanceSubscription: any;
  adminSubscription: any;
  superAdminSubscription: any;
  profile_name: any;
  profile_image: any = 'assets/images/users/account.webp';
  mode: any = "light";
  balance: any;
  admin = false;
  super = false;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    private ws: WebsocketService,
    private es: EventService,
    private mdw: MiddlwareService
  ) { }

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }
    this.profileSubscription = this.ws.getMyProfileContainer$.subscribe(profileData => {
      if(profileData != null){
        this.profile_image = profileData.data.profile_image;
        this.profile_name = profileData.data.profile_name;
      }
    });
    this.getBalanceSubscription = this.ws.getBalanceContainer$.subscribe(balanceData => {
      // console.log(balanceData);
      if(balanceData){
        this.balance = balanceData.data.balance;
      }
    })
    this.adminSubscription = this.mdw.showAdminContainer$.subscribe((data)=>{
      this.admin = data;
      // console.log(this.admin);
    })
    this.superAdminSubscription = this.mdw.showRootAdminContainer$.subscribe((data)=>{
      this.super = data;
      // console.log(this.super);
    })
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleMode() {
    if(this.mode === "light"){
      this.mode = "dark";
      this.es.broadcast('changeMode', "dark");
    } else if (this.mode === "dark"){
      this.mode = "light";
      this.es.broadcast('changeMode', "light");
    }
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  routeRecharge() {
    this.router.navigate(['/account/recharge']);
  }

  /**
   * Logout the user
   */
  logout() {
    this.ws.logout();
  }

  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }
}
