import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MiddlwareService } from './middleware.service';
import { Router } from '@angular/router';
import { WebsocketService } from './websocket.service';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

  public _signupContainer = new Subject<any>;
  public signupContainer$ = this._signupContainer.asObservable();
  public _loginContainer = new Subject<any>;
  public loginContainer$ = this._loginContainer.asObservable();
  public _verifyContainer = new Subject<any>;
  public verifyContainer$ = this._verifyContainer.asObservable();
  errorMap = {
    401: "Email or password incorrect",
    505: "Account already exists",
    504: "Code is invalid"
  }

  constructor(
    private mdw: MiddlwareService,
    private ws: WebsocketService,
    private router: Router
  ) {
  }

  async login(email: any, pass: any, google_token: any){
    let request = fetch(environment.http_url + '/login', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'email': email,
        'pass': pass,
        'google_token': google_token
      })
    });

    request.then((response: any) => {
      if (!response.ok) {
        // console.log(response);
        if(response.status === 401){
          this._loginContainer.next({response: false, code: response.status});
        }
      }
      return response.json();
    }).then((data) => {
      if(data.token != null && data.email != null && data.id != null) {
        this.ws.sendRequest(JSON.stringify({method: 'TOKEN_AUTH', token: data.token, email: data.email, id: data.id}));
        this._loginContainer.next({response: true})
      }
    }).catch((err: any) => {
      // console.log(err);
    });
  }

  async loginAdmin(email: any, pass: any, google_token: any, id: any){
    let request = fetch(environment.http_url + '/admin', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'email': email,
        'pass': pass,
        'google_token': google_token,
        'id': id
      })
    });

    request.then((response: any) => {
      if (!response.ok) {
        // console.log(response);
        if(response.status === 401){
          this._loginContainer.next({response: false, code: response.status});
        }
      }
      return response.json();
    }).then((data) => {
      if(data.token != null && data.email != null && data.id != null) {
        this.ws.sendRequest(JSON.stringify({method: 'TOKEN_AUTH', token: data.token, email: data.email, id: data.id}));
        this._loginContainer.next({response: true})
      }
    }).catch((err: any) => {
      // console.log(err);
    });
  }

  async signup(name: any, email: any, pass: any, google_token: any, cc: any){
    if(cc == null){
      cc =
      {
        "name":"Unknown",
        "alpha":"XX",
        "code":"000"
      }
    }

    let request = fetch(environment.http_url + '/signup', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'email': email,
        'pass': pass,
        'google_token': google_token,
        'name': name,
        'p_code': cc.code,
        'cc': cc.alpha
      })
    });

    request.then((response: any) => {
      if (!response.ok) {
        // console.log(response);
        if(response.status === 401){
          // console.log("Signup Failed");
        }
        if(response.status === 505){
          this._signupContainer.next({response: false, code: response.status});
        }
      }
      return response.json();
    }).then((data) => {
      // console.log(JSON.stringify(data));
      if(data.status === "SUCCESS"){
        localStorage.setItem("email", data.email);
        this.router.navigate(['/hub/verification']);
        this._signupContainer.next({response: true})
      } else if(data.token != null && data.email != null && data.id != null) {
        this.ws.sendRequest(JSON.stringify({method: 'TOKEN_AUTH', token: data.token, email: data.email, id: data.id}));
        this._signupContainer.next({response: true})
      }
    }).catch((err: any) => {
      // console.log(err);
    });
  }

  async resend(email: any){
    let request = fetch(environment.http_url + '/emailVerify', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'email': email
      })
    });

    request.then((response: any) => {
      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`);
      }
      return response.json();
    }).then((data) => {
      // console.log(data.status);
    }).catch((err: any) => {
      // console.log(err);
    });
  }

  async email_verify(otp: any, email: any){
    let request = fetch(environment.http_url + '/verify', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'otp': otp,
        'email': email
      })
    });

    request.then((response: any) => {
      if (!response.ok) {
        if(response.status === 504){
          this._verifyContainer.next({response: false, code: response.status});
        }
      }
      return response.json();
    }).then((data) => {
      // console.log(data);
      if(data.token != null && data.email != null && data.id != null) {
        this.ws.sendRequest(JSON.stringify({method: 'TOKEN_AUTH', token: data.token}));
        this._verifyContainer.next({response: true})
      }
    }).catch((err: any) => {
      // console.log(err);
    });
  }

  async send_email(email: any){
    let request = fetch(environment.http_url + '/emailVerify', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'email': email
      })
    });

    request.then((response: any) => {
      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`);
      }
      return response.json();
    }).then((data) => {
      // console.log(data.status);
      if(data.status === "SUCCESS"){
        this.mdw._emailContainer.next(email);
        this.router.navigate(['/hub/change-password']);
      }
    }).catch((err: any) => {
      // console.log(err);
    });
  }

  async changePassword(pass: any, otp: any){
    let request = fetch(environment.http_url + '/changePassword', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'email': this.mdw.getEmail(),
        'pass': pass,
        'otp': otp
      })
    });

    request.then((response: any) => {
      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`);
      }
      return response.json();
    }).then((data) => {
      // console.log(data.status);
      if(data.status === "SUCCESS"){
        this.router.navigate(['/hub/login'])
        .then(() =>
          window.location.reload()
        );
      }
    }).catch((err: any) => {
      // console.log(err);
    });
  }
}

