import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIModule } from './ui/ui.module';

import { SellingchartComponent } from './sellingchart/sellingchart.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImagecropperComponent } from './imagecropper/imagecropper.component';

@NgModule({
  declarations: [SellingchartComponent, ImagecropperComponent],
  imports: [
    CommonModule,
    UIModule,
    NgApexchartsModule,
    ImageCropperModule
  ],
  exports: [SellingchartComponent, ImagecropperComponent]
})
export class SharedModule { }
