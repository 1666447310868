import { MenuItem } from '../sidebar/menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: "MENUITEMS.SEARCH.TEXT",
    link: "/search",
    icon: "bx bx-search-alt",
  },
  {
    id: 2,
    label: "MENUITEMS.ORDERS.TEXT",
    link: "/orders/myorders",
    icon: "bx bx-purchase-tag-alt",
  },
  {
    id: 3,
    label: "MENUITEMS.MYDOMAINS.TEXT",
    icon: "mdi mdi-web",
    subItems: [
      {
        id: 4,
        label: "MENUITEMS.MYDOMAINS.LIST.LISTMYDOMAINS",
        link: "/mydomains/domains",
        parentId: 3,
      },
      {
        id: 5,
        label: "MENUITEMS.MYDOMAINS.LIST.ADDDOMAIN",
        link: "/mydomains/add-domain",
        parentId: 3,
      },
    ],
  },
  {
    id: 6,
    label: "MENUITEMS.ACCOUNT.TEXT",
    icon: "bx bxs-user-detail",
    subItems: [
      {
        id: 7,
        label: "MENUITEMS.ACCOUNT.LIST.RECHARGE",
        link: "/account/recharge",
        parentId: 6,
      },
      {
        id: 8,
        label: "MENUITEMS.ACCOUNT.LIST.PAYOUT",
        link: "/account/payout",
        parentId: 6,
      },
      {
        id: 9,
        label: "MENUITEMS.ACCOUNT.LIST.PAYMENTHISTORY",
        link: "/account/billinghistory",
        parentId: 6,
      },
    ],
  },
  {
    id: 10,
    label: "MENUITEMS.ADMIN.TEXT",
    icon: "bx bx-lock-alt",
    subItems: [
      // {
      //   id: 11,
      //   label: "MENUITEMS.ADMIN.LIST.API",
      //   link: "/admin/api",
      //   parentId: 10,
      // },
      {
        id: 12,
        label: "MENUITEMS.ADMIN.LIST.TRANSFER",
        link: "/admin/transfer",
        parentId: 10,
      },
    ],
  }
];
